<template>
  <div class="block-info mb-2">
    <div class="block-info__title-container"  @click="toggleSection">
      <span class="block-info__title">{{ $t(`${typeUpperCase}.TITLES_FIELDS.${title}`) }}</span>
      <span class="block-info__icon-toggle">
        <v-icon color="#fff">{{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </span>
    </div>

    <div v-if="isOpen" class="block-info__data-wrapper">
      <template v-if="data.length <= 1 && title !== 'mission'">
        <div v-for="(item, key) in data[0]" :key="key">
          <template v-if="!Array.isArray(item)">
            <div v-if="item" class="block-info__data-item">
              <span class="block-info__item-key">{{ $t(`${typeUpperCase}.TITLES_FIELDS.${key}`) }}</span>
              <span class="block-info__item-value">{{ item }}</span>
            </div>
          </template>
          <template v-else>
            <div v-if="item.length" class="block-info__data-item">
              <span class="block-info__item-key">{{ $t(`${typeUpperCase}.TITLES_FIELDS.${key}`) }}</span>
              <div class="block-info__item-value">
                <span v-for="(i, index) in item" class="block-info__item-value" :key="index">
                  {{ i }}
                </span>
              </div>
            </div>
          </template>
        </div>
      </template>

      <template v-else>
        <div v-for="(item, index) in data" :key="index">
          <div v-if="Object.keys(item).length !== 0 || item.length" class="mb-1 block-info__data-wrapper">
            <template v-for="(i, key) in item">
              <div v-if="i && typeof i !== 'object' && isNaN(key) " class="block-info__data-item block-info__data-item-array" :key="key">
                <span class="block-info__item-key">{{ $t(`${typeUpperCase}.TITLES_FIELDS.${key}`) }}</span>
                <span class="block-info__item-value">{{ i }}</span>
              </div>
              <div v-if="i && typeof i !== 'object' && !isNaN(key)" class="block-info__data-item block-info__data-item-array" :key="key">
                <span class="block-info__item-value-non-title" >{{ i }}</span>
              </div>
              <div v-if="typeof i === 'object'" :key="key">
                <template v-for="(prop, key) in i">
                  <div v-if="prop" class="block-info__data-item block-info__data-item-array" :key="key">
                    <span class="block-info__item-key">{{ $t(`${typeUpperCase}.TITLES_FIELDS.${key}`) }}</span>
                    <span class="block-info__item-value">{{ prop }}</span>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlockInfo',
  props: {
    title: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: () => ([]),
    },
    type: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      isOpen: true,
    };
  },
  computed: {
    typeUpperCase() {
      return this.type.toUpperCase();
    },
  },
  methods: {
    toggleSection() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss">
$titleOfSectionWidth: 300px;

.block-info {
  border-bottom: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
}

.block-info__title-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 23px;
  width: 100%;
  border: 1px solid $color-dark-blue;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: $color-white;
  background: $color-dark-blue;
  cursor: pointer;
  text-transform: capitalize;
  transition-property: background-color, color;
  transition-duration: 0.4s;

  &:hover {
    background-color: darken($color-dark-blue, 3%);
  }
}

.block-info__data-wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: auto;
  max-height: 3000px;
  transition: all 0.6s ease;

  &:last-child {
    .dataItem:last-child {
      border-radius: 0 0 4px 4px;
    }
  }
}

.block-info__title {
  width: 100%;
  overflow: hidden;
  margin-right: 5px;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.block-info__data-item {
  display: flex;
  padding: 10px 23px;
  width: 100%;
  border: 1px solid $color-dark-blue;
  border-top: none;
  font-size: 20px;
  line-height: 30px;
  background: $color-dark-blue;
  font-weight: 600;
  color: $color-black;
  background: $color-white;
}

.block-info__data-item-array {
  &:first-child {
    border-top: 1px solid $color-dark-blue;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }
}

.block-info__item-key {
  min-width: $titleOfSectionWidth;
  margin-right: 5px;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.block-info__item-value {
  display: block;
  white-space: pre-line;
  width: 100%;
  font-weight: normal;
}
</style>
