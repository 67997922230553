<template>
  <div class="rate">
    <RateAction @setRate="toogleRateDialog" />
    <slot name="rate-actions" />
    <RateDialog v-if="dialog" @closeDialog="toogleRateDialog" class="rate-dialog" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Dialog from '@/components/Dialog'
import RateAction from '@/components/RateAction'
import RateDialog from '@/components/RateDialog'

export default {
  components: {
    Dialog,
    RateAction,
    RateDialog,
  },
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    ...mapGetters({
      parsingSteps: 'cv/getParsingSteps',
    }),
  },
  methods: {
    toogleRateDialog(...[, state]) {
      this.dialog = state
    },
  },
}
</script>


<style lang="scss">
.rate {
  display: flex;
  max-width: 800px;
  margin: 50px auto;
  justify-content: space-between;
}
</style>