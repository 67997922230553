<template>
  <v-row class="listed-view mt-6">
    <v-col v-if="isData" class="col-12 listed-view__listed-container">
      <div class="listed-view__section">
        <template v-for="({ title, data, show }, index) in blockData">
          <BlockInfo v-if="show" :title="title" :data="data" :key="index" :type="type" />
        </template>
      </div>
      <Rate>
        <template v-slot:rate-actions>
           <div class="listed-view__actions">
            <v-tooltip bottom transition="none">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  x-large
                  class="listed-view__actions-back"
                  v-bind="attrs"
                  v-on="on"
                  @click="backToList"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
              </template>
              {{ $t('COMMON.BUTTON.BACK') }}
            </v-tooltip>
          </div>
        </template>
      </Rate>
    </v-col>
    <v-col v-else class="col-12 cv-listed__no-data">
      {{ $t("COMMON.NO_DATA") }}
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

import BlockInfo from '@/components/BlockInfo'
import Rate from '@/components/Rate'

export default {
  name: 'ListedView',
  props: {
    type: {
      type: String,
    },
  },
  components: {
    BlockInfo,
    Rate,
  },
  computed: {
    ...mapGetters({
      cv: 'cv/getCV',
      personalData: 'cv/getPersonalData',
      educationData: 'cv/getEducationData',
      experienceData: 'cv/getExperienceData',
      skillsData: 'cv/getSkillsData',
      job: 'job/getJob',
      offerInformationData: 'job/getOfferInformationData',
      missionsData: 'job/getMissionsData',
      requirementSkillsData: 'job/getSkillsData',
      requirementsData: 'job/getRequirementsData',
    }),

    isData() {
      if(this.type === 'cv') {
        return this.personalData && this.personalData.length ||
               this.educationData && this.educationData.length ||
               this.experienceData && this.experienceData.length ||
               this.skillsData && this.skillsData.length ? true : false
      }

      if(this.type === 'job') {
        return this.offerInformationData && this.offerInformationData.length ||
               this.missionsData && this.missionsData.length ||
               this.requirementsData && this.requirementsData.length ||
               this.requirementSkillsData && this.requirementSkillsData.length ? true : false
      }

      return false
    },

    blockData() {
      if(this.type === 'cv') {
        return [
          {
            title: 'Personal',
            data: this.personalData,
            show: this.personalData.length && Object.keys(this.personalData[0]).length !== 0
          },
          {
            title: 'Education',
            data: this.educationData,
            show: this.educationData.length && Object.keys(this.educationData[0]).length !== 0
          },
          {
            title: 'Experience',
            data: this.experienceData,
            show: this.experienceData.length && Object.keys(this.experienceData[0]).length !== 0
          },
          {
            title: 'Skills',
            data: this.skillsData,
            show: this.skillsData.length && Object.keys(this.skillsData[0]).length !== 0
          },
        ]
      }

      if(this.type === 'job') {
        return [
          {
            title: 'offer_information',
            data: this.offerInformationData,
            show: this.offerInformationData.length && Object.keys(this.offerInformationData[0]).length !== 0,
          },
          {
            title: 'mission',
            data: this.missionsData,
            show: this.missionsData.length && Object.keys(this.missionsData[0]).length !== 0,
          },
          {
            title: 'requirements',
            data: this.requirementsData,
            show: this.requirementsData.length && Object.keys(this.requirementsData[0]).length !== 0,
          },
          {
            title: 'skills',
            data: this.requirementSkillsData,
            show: this.requirementSkillsData.length && Object.keys(this.requirementSkillsData[0]).length !== 0,
          },
        ]
      }

      return []
    },
  },
  methods: {
    backToList() {
      this.$router.push('/xranking/cvs/')
    },
  },
}
</script>

<style lang="scss">
.listed-view {
  width: 95%;
  margin: 0 auto;
  overflow: scroll;
}

.listed-view__section {
  max-width: 800px;
  margin: 0 auto 30px;
}

.listed-view__actions {
  display: flex;
  align-items: center;
}

.listed-view__actions-back.theme--light.v-btn.v-btn--icon {
  height: 70px;
  width: 70px;
  color: $color-white;
  background-color: $color-dark-blue;

  &:hover {
    color: $color-dark-blue;
    background-color: $color-white;
    border: 2px solid $color-dark-blue;
  }

 .v-icon {
    font-size: 40px;
  }
}

.listed-view__no-data {
  text-align: center;
  font-size: 36px;
  color: $color-dark-blue;
}
</style>
