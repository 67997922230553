<template>
  <Dialog
    value
    icon
    width="880px"
    class="dialog-rate"
    dialogRate
    :title="$t('DIALOG.RATE_DIALOG_TITLE')"
    @closeDialog="closeDialog"
  >
    <v-card flat class="pa-0 pt-6 elevation-5 white">
      <v-row class="rate-parsing__steps d-flex text-center flex-wrap">
        <v-col
          v-for="({meta, text, url}, index) in parseSteps"
          :key="`${meta}-${index}`"
          class="rate-parsing__step col-12 col-sm-4 col-md-3 px-4 mb-4"
        >
          <img
            v-if="url"
            :src="`${baseUrlForImage}/${url}`"
            class="rate-parsing__step-image"
          >
          <textarea
            v-if="text"
            :value="text"
            disabled
            class="rate-parsing__step-text"
          />
        </v-col>
      </v-row>
      <v-divider class="dialog-rate__devider ml-4 mr-4" />
      <v-card-actions class="rate-dialog__actions">
        <RateAction
          class="rate-actions-dialog"
          @setRate="setRate"  
        />
      </v-card-actions>
    </v-card>
  </Dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import Dialog from '@/components/Dialog'
import RateAction from '@/components/RateAction'

export default {
  components: {
    Dialog,
    RateAction,
  },
  computed: {
    ...mapGetters({
      parsingSteps: 'cv/getParsingSteps',
    }),

    parseSteps() {
      return this.parsingSteps.parsing_steps
    },

    baseUrlForImage() {
      return process.env.VUE_APP_FILESHARE_PUBLIC
    },
  },
  methods: {
    setRate(value) {
      const { id } = this.parsingSteps
      const params = {
        feedback: {
          global: `${value}`,
        },
      }

      this.$services.xrate_ra.rate.set(id, params)
        .then(response => {
          const message = 'Rating added successfully'
          this.$store.dispatch('messenger/add', {type: 'success', code: 'XRANKING.ADD_RATE_SUCCESSFULLY',
            message, time: 5000 })
        })
        .catch(({ error, details: { err: { msg }}}) => {
          const _message = msg || error?.message
          this.$store.dispatch('messenger/add', {type: 'error', code: 'XRANKING.SERVER_ERROR',
            _message, time: 5000 })
        })
    },

    closeDialog() {
      this.$emit('closeDialog', false)
    },
  },
}
</script>

<style lang="scss">
.rate-parsing__steps {
  max-height: 400px;
  max-width: 100%;
  padding-left: 16px;
  overflow-y: scroll;

  @media screen and (max-width: 600px) {
    
  }
}

.rate-dialog__actions {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  z-index: 333;
  background-color: $color-white;
}

.rate-parsing__step-image {
  width: 100%;
}

.rate-parsing__step {
  min-height: 305px;
}

.rate-parsing__step-text {
  width: 100%;
  height: 100%;
}
</style>
